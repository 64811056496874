@use 'global/mixin' as m;
@use 'global/variable' as v;

.l-header {
  width: 100%;
  padding: 30px 0;
  background: url('../img/common/bg_header.jpg') no-repeat center;
  background-size: cover;
  @include m.sp {
    padding: 2rem 0;
  }

  &_wrap {
    max-width: 1144px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include m.pc {
      width: 94%;
    }
    @include m.sp {
      padding: 0 2rem;
    }
  }

  &_logo {
    width: 153px;
    position: relative;
    @include m.tab {
      width: 130px;
    }
    @include m.sp {
      width: 9.5rem;
    }
    &:after {
      content: '';
      width: 0.1rem;
      height: 40px;
      background-color: v.$black02;
      position: absolute;
      right: -25px;
      top: 50%;
      transform: translate(-50%, -50%);
      @include m.tab02 {
        right: -1.5rem;
      }
      @include m.sp {
        height: 3rem;
      }
    }

    a {
      display: block;
    }
  }

  &_title {
    display: flex;
    align-items: center;
    gap: 0 50px;
    @include m.tab02 {
      gap: 0 3rem;
    }
    p {
      font-size: 22px;
      @include m.tab {
        font-size: 18px;
      }
      @include m.sp {
        font-size: 1.5rem;
        white-space: nowrap;
      }
    }
  }

  &_btn {
    max-width: 262px;
    width: 100%;
  }

  &_menu {
    margin-top: 45px;
    display: flex;
    justify-content: center;
    gap: 0 60px;
    @include m.sp {
      margin-top: 2.5rem;
      align-items: center;
      gap: 0 2rem;
    }

    >li {
      width: auto;
      @include m.pc {
        height: 40px;
        line-height: 40px;
      }
      &:not(:first-child) {
        position: relative;
        &:before {
          content: '';
          width: 0.1rem;
          height: 100%;
          background-color: v.$black02;
          position: absolute;
          top: 50%;
          left: -30px;
          transform: translate(50%,-50%);
          @include m.sp {
            height: 4.5rem;
            left: -1rem;
          }
        }
      }

      a {
        font-size: 16px;
        text-decoration: underline;
        @include m.sp {
          font-size: 1.4rem;
          line-height: 1.6;
          text-align: center;
          display: block;
        }
      }
    }
  }
}