@use 'global/mixin' as m;
@use 'global/variable' as v;

.p-flow {
  margin-top: 100px;
  padding: 100px 0 95px;
  background-color: v.$offwhite02;
  @include m.sp {
    margin-top: 5rem;
    padding: 4rem 0;
  }

  &_list {
    color: v.$black02;
    margin-top: 50px;
    @include m.sp {
      margin-top: 3rem;
    }
    li {
      padding: 30px 13.3%;
      background-color: v.$white;
      display: flex;
      align-items: center;
      position: relative;
      @include m.sp {
        padding: 2.5rem;
      }
      &:not(:first-child) {
        margin-top: 45px;
        @include m.sp {
          margin-top: 3rem;
        }

        &:before {
          content: '';
          width: 40px;
          height: 62px;
          margin: 0 auto;
          background: url('../img/ico_arrow01.svg') no-repeat center;
          background-size: contain;
          position: absolute;
          right: 0;
          left: 0;
          top: -55px;
          @include m.sp {
            width: 2.5rem;
            height: 3.9rem;
            top: -3.4rem;
          }
        }
      }
    }
  }

  &_icon {
    width: 64px;
    margin-right: 25px;
    @include m.sp {
      width: 4.5rem;
      margin-right: 2rem;
    }
  }

  &_text {
    font-feature-settings: 'palt';
    flex: 1;
    @include m.sp {
      font-size: 1.4rem;
    }
  }

  &_date {
    font-size: 16px;
    position: absolute;
    left: 54.8%;
    bottom: -34px;
    @include m.sp {
      font-size: 1.4rem;
      left: 19rem;
      bottom: -2.5rem;
    }
  }
}