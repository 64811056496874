@use 'global/mixin' as m;
@use 'global/variable' as v;

html {
  font-size: 62.5%;
  @include m.sp {
    font-size: calc(100vw / 37.5);
  }
}

body {
  color: v.$black;
  font-family: 'Noto Sans JP', sans-serif;
  font-weight: 400;
}

.pc {
  @include m.pc {
    display: block;
  }

  @include m.sp {
    display: none;
  }
}

.sp {
  @include m.pc {
    display: none;
  }

  @include m.sp {
    display: block;
  }
}

.bold {
  font-weight: bold;
}

.center {
  text-align: center;
}