@use 'global/mixin' as m;
@use 'global/variable' as v;

.c-btn {
  &_basic {
    color: v.$white;
    font-size: 16px;
    width: 100%;
    height: 64px;
    line-height: 64px;
    background-color: v.$green;
    border-radius: 99px;
    display: block;
    transition: opacity .12s ease-out;
    @include m.sp {
      font-size: 1.4rem;
      height: 5.5rem;
      line-height: 5.5rem;
    }
    &:hover {
      opacity: .8;
    }

    &--follow {
      @extend .c-btn_basic;
      font-size: 22px;
      height: 80px;
      line-height: 80px;
      box-shadow: 0px 3px 16px 0px rgba(v.$black, 0.2);
      @include m.sp {
        font-size: 1.8rem;
        height: 6rem;
        line-height: 5.7rem;
      }
    }
  }
}