@use 'global/mixin' as m;
@use 'global/variable' as v;

.p-request {
  margin-top: 50px;
  padding-top: 50px;
  @include m.sp {
    margin-top: 3rem;
    padding-top: 3rem;
  }

  &_lead {
    margin-top: 30px;
    @include m.sp {
      text-align: left;
      margin-top: 2rem;
    }
  }

  &_list {
    margin-top: 5rem;
    @include m.pc {
      display: flex;
      justify-content: space-between;
    }
    @include m.sp {
      margin-top: 3rem;
    }

    > li {
      padding: 60px 4.37% 40px;
      background-color: rgba(v.$green, 0.06);
      flex-basis: 49.3%;
      @include m.sp {
        padding: 3rem 2.5rem;
      }
      &:last-child {
        @include m.sp {
          margin-top: 3rem;
        }
      }

      h3 {
        color: v.$green;
        font-size: 32px;
        @include m.tab {
          font-size: 26px;
        }
        @include m.sp {
          font-size: 2.2rem;
        }
  
        span {
          padding-left: 75px;
          position: relative;
          @include m.tab {
            padding-left: 55px;
          }
          @include m.sp {
            padding-left: 5rem;
          }
          &:before {
            content: '';
            width: 64px;
            height: 64px;
            background: url('../img/ico_check02.svg') no-repeat center;
            background-size: contain;
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            @include m.tab {
              width: 45px;
              height: 45px;
            }
            @include m.sp {
              width: 4rem;
              height: 4rem;
            }
          }
        }
      }

      ul {
        width: 100%;
        margin-top: 40px;
        display: flex;
        flex-direction: column;
        gap: 5px 0;
        @include m.pc {
          height: 220px;
        }
        @include m.sp {
          margin-top: 2.5rem;
        }

        > li {
          color: v.$black02;
          font-size: 20px;
          background-color: rgba(v.$green, 0.06);
          flex-grow: 1;
          display: flex;
          align-items: center;
          justify-content: center;
          @include m.tab {
            font-size: 18px;
          }
          @include m.sp {
            font-size: 1.6rem;
            height: 5.5rem;
          }
        }
      }

      &:nth-child(2) {
        background-color: v.$offwhite02;

        h3 {
          color: rgba(v.$black, 0.4);

          span {
            &:before {
              background-image: url('../img/ico_cross02.svg');
            }
          }
        }

        ul {
          > li {
            background-color: rgba(v.$black, 0.06);
          }
        }
      }
    }
  }

  &_btn {
    width: 100%;
    margin: 40px auto 0;
    @include m.pc {
      max-width: 262px;
    }
    @include m.sp {
      margin-top: 2.5rem;
    }
  }
}