@use 'global/mixin' as m;
@use 'global/variable' as v;

.p-instr {
  padding-top: 65px;
  @include m.sp {
    padding-top: 4rem;
  }

  &_lead {
    margin-top: 30px;
    @include m.sp {
      margin-top: 2rem;
      text-align: left;
    }
  }

  &_wrap {
    margin-top: 50px;
    @include m.sp {
      margin-top: 3rem;
    }
  }

  &_example {
    margin-top: 2rem;

    > li {
      &:not(:first-child) {
        margin-top: 2rem;
      }
    }
  }

  &_bad {
    margin-top: 50px;
    @include m.sp {
      margin-top: 3rem;
    }
  }
}