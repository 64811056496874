@use 'global/mixin' as m;
@use 'global/variable' as v;

.l-wrap {
  max-width: 1144px;
  width: 100%;
  margin: 0 auto;
  @include m.pc {
    width: 94%;
  }
  @include m.sp {
    padding: 0 2rem;
  }

  &--small {
    width: 100%;
    margin: 0 auto;
    @include m.pc {
      max-width: 752px;
    }
  }
}