@use 'global/mixin' as m;
@use 'global/variable' as v;

.p-other {
  padding: 60px 0 50px;
  @include m.sp {
    padding: 3rem 0;
  }

  &_list {
    display: flex;
    justify-content: center;
    align-items: center;

    > li {
      padding: 0 25px;
      @include m.sp {
        padding: 0 1rem;
        text-align: center;
      }
      &:not(:first-child) {
        position: relative;
        &:before {
          content: '';
          width: 0.1rem;
          height: 40px;
          background-color: v.$black02;
          position: absolute;
          top: 50%;
          left: 0;
          transform: translateY(-50%);
        }
      }

      a {
        font-size: 16px;
        text-decoration: underline;
        @include m.sp {
          font-size: 1.4rem;
        }
      }
    }
  }

  &_logo {
    width: 153px;
    margin: 70px auto 0;
    @include m.sp {
      width: 12rem;
      margin-top: 3rem;
    }
  }
}