@use 'global/mixin' as m;
@use 'global/variable' as v;

.c-example {
  padding: 40px 6.4%;
  border: 0.4rem solid v.$lightBlue;
  position: relative;
  @include m.sp {
    padding: 3.5rem 2.5rem 2rem;
    border-width: 0.3rem;
  }

  &_icon {
    color: v.$white;
    font-size: 16px;
    width: 82px;
    height: 32px;
    line-height: 28px;
    padding-left: 33px;
    background-color: v.$lightBlue;
    position: absolute;
    top: -4px;
    left: -4px;
    display: block;
    @include m.sp {
      font-size: 1.4rem;
      padding-left: 2.5rem;
      width: 7.2rem;
      height: 2.9rem;
      line-height: 2.5rem;
      top: -0.3rem;
      left: -0.3rem;
    }
    &:before {
      content: '';
      width: 16px;
      height: 11px;
      background: url('../img/ico_check.svg') no-repeat center;
      background-size: contain;
      position: absolute;
      top: 50%;
      left: 10px;
      transform: translateY(-50%);
      @include m.sp {
        width: 1.1rem;
        height: 0.8rem;
        left: 0.9rem;
      }
    }
  }

  &_title {
    color: v.$lightBlue;
    font-size: 20px;
    @include m.sp {
      font-size: 1.8rem;
    }
  }

  &_instr {
    color: v.$black02;
    padding: 15px 10px;
    margin-top: 1.5rem;
    background-color: v.$offWhite;
    border: 0.1rem solid v.$lightGray;
    @include m.pc {
      display: flex;
      gap: 0 20px;
    }
    @include m.sp {
      padding: 1rem 1.5rem;
    }

    p {
      flex: 1;
      position: relative;
      @include m.sp {
        margin-top: 0.5rem;
        padding-top: 0.5rem;
      }
      &:before {
        content: '';
        width: 0.1rem;
        height: 100%;
        background-color: v.$black02;
        position: absolute;
        top: 0;
        left: -10px;
        @include m.pc {
          transform: translateX(50%);
        }
        @include m.sp {
          width: 100%;
          height: 0.1rem;
          left: 0;
        }
      }
    }
  }

  &_table {
    margin-top: 1.5rem;

    th,td {
      text-align: left;
      @include m.sp {
        display: block;
      }
    }

    td {
      @include m.sp {
        margin-top: 0.8rem;
      }
    }

    tr {
      &:not(:first-child) {
        @include m.sp {
          margin-top: 1.2rem;
          display: block;
        }
        th,td {
          @include m.pc {
            padding-top: 1.5rem;
          }
        }
      }
    }
    th {
      color: v.$black02;
      font-size: 14px;
      line-height: 1.5;
      vertical-align: top;
      @include m.pc {
        width: 138px;
      }
      @include m.sp {
        font-size: 1.3rem;
      }
    }
  }

  &--bad {
    @extend .c-example;
    border-color: v.$red;

    .c-example {
      &_icon {
        line-height: 30px;
        background-color: v.$red;
        @include m.sp {
          line-height: 2.6rem;
        }
        &:before {
          width: 13px;
          height: 13px;
          background-image: url('../img/ico_cross.svg');
          @include m.sp {
            width: 1.1rem;
            height: 1.1rem;
            left: 0.7rem;
          }
        }
      }

      &_title {
        color: v.$red;
      }
    }
  }
}