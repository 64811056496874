@charset "UTF-8";

// color
//black
$black: #000;
$black02: #4C4C4C;

// white
$white: #fff;
$offWhite: #F5F5F5;
$offwhite02: #F7F7F7;

// green
$green: #00A78F;
$greenPale: #D9F2EE;

// blue
$lightBlue: #1E88E5;

// gray
$lightGray: #E0E0E0;

// red
$red: #D84315;