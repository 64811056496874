@use 'global/mixin' as m;
@use 'global/variable' as v;

.c-title {
  &_sec {
    font-size: 40px;
    letter-spacing: 0.1rem;
    @include m.tab {
      font-size: 34px;
    }
    @include m.sp {
      font-size: 2.4rem;
    }
  }

  &_ex {
    color: v.$lightBlue;
    font-size: 32px;
    line-height: 1;
    padding-left: 48px;
    position: relative;
    @include m.sp {
      font-size: 2rem;
      padding-left: 3.4rem;
    }
    &:before {
      content: '';
      width: 40px;
      height: 40px;
      background: url('../img/ico_good.png') no-repeat center;
      background-size: contain;
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      @include m.sp {
        width: 2.8rem;
        height: 2.8rem;
      }
    }

    &--bad {
      @extend .c-title_ex;
      color: v.$red;
      &:before {
        background-image: url('../img/ico_bad.png');
      }
    }
  }
}