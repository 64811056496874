@use 'global/mixin' as m;
@use 'global/variable' as v;

.l-footer {
  padding: 1.5rem 0 140px;
  background-color: v.$greenPale;
  @include m.sp {
    padding-bottom: 10.5rem;
  }

  &_wrap {
    max-width: 1100px;
    width: 100%;
    margin: 0 auto;
    @include m.pc {
      width: 94%;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    @include m.sp {
      padding: 0 2rem;
    }
  }

  &_menu {
    display: flex;
    gap: 0 30px;
    @include m.sp {
      width: 29rem;
      margin: 0 auto;
      flex-wrap: wrap;
      justify-content: center;
      gap: 1rem 2rem;
    }

    > li {
      &:not(:first-child) {
        position: relative;
        &:before {
          content: '';
          width: 0.1rem;
          height: 1.2rem;
          background-color: v.$black02;
          position: absolute;
          top: 50%;
          left: -15px;
          transform: translate(50%,-50%);
          @include m.sp {
            left: -1rem;
          }
        }
      }
      &:nth-child(odd) {
        @include m.sp {
          &:before {
            display: none;
          }
        }
      }
      a {
        color: v.$black02;
        font-size: 1.2rem;
        line-height: 1;
      }
    }
  }

  &_copyright {
    color: v.$black02;
    font-size: 1.2rem;
    line-height: 1;
    @include m.sp {
      margin-top: 1rem;
      text-align: center;
    }
  }

  &_btn {
    width: 100%;
    margin: 0 auto;
    position: fixed;
    bottom: 35px;
    right: 0;
    left: 0;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.3s;
    @include m.pc {
      max-width: 458px;
    }
    @include m.sp {
      width: calc(100% - 4rem);
      bottom: 2.5rem;
    }

    &.is-view {
      opacity: 1;
      pointer-events: all;
    }
  }
}