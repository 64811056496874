@use 'global/mixin' as m;
@use 'global/variable' as v;

.c-text {
  color: v.$black02;
  font-size: 18px;
  line-height: 1.77;
  letter-spacing: 0.05rem;
  @include m.sp {
    font-size: 1.6rem;
  }

  &--small {
    font-size: 14px;
    line-height: 1.5;
    @include m.sp {
      font-size: 1.3rem;
    }
  }
}